import React, { Component } from "react";
import { Navbar, Container, Nav } from 'react-bootstrap';
import "./navbar.css";

class NavigationBar extends Component {
  render() {
    const {active} = this.props;
    console.log(active)
    return (
        <Navbar bg="bg-white" >
          <Container  className="justify-content-end">
            <Nav activeKey={ active }>
              <Nav.Item>
                <Nav.Link eventKey="home" href="/">Home</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="article" href="/article">Article</Nav.Link>
              </Nav.Item> */}
              {/* <Nav.Item>
                <Nav.Link eventKey="contact" href="/contact">Contact</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="about" href="/about">About</Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Container>
        </Navbar>
    );
  }
}

export default NavigationBar;